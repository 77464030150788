import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

const Password = (props) => {

  const handleSubmit = async (event) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    event.preventDefault();

    if (!email.value) {
      // Если поле email пустое, показываем тост с ошибкой
      Toast.fire({
        icon: "error",
        title: "Поле Email обязательно для заполнения.",
      });
      return; // Прекращаем выполнение функции
    }

    await axios.post("/password_reset_create", { email: email.value })
      .then(function (response) {
        console.log(response)
        Toast.fire({
          icon: 'success',
          title: "Если вы верно указали почту, то вам придет письмо с ссылкой для смены пароля"
        })


      })
      .catch(function (error) {
        console.log(error)
        Toast.fire({
          icon: 'success',
          title: "Если вы верно указали почту, то вам придет письмо с ссылкой для смены пароля"
        })


      });


  };


  return (
    <React.Fragment>
      <>
        <section className="grid h-screen place-content-center bg-black text-white relative">
          <form
            onSubmit={handleSubmit}
            action="/password_reset_create"
            method="post"
            className="flex flex-col items-center justify-center space-y-6 z-10 "
          >
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 placeholder:text-white border border-white"
            />
            <button
              type="submit"
              className="rounded-lg bg-[#801EE2] h-[49px] px-4 text-white w-full"
            >
              Отправить
            </button>
            <a
              href="/login?"
              className="rounded-lg bg-white h-[49px] px-4 text-black w-full align-bottom text-center p-3.5"
            >
              Назад
            </a>
          </form>
        </section>

        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n  .contax{\n    background-color: transparent;\n    backdrop-filter: blur(10px);\n  }\n"
          }}
        />
      </>
    </React.Fragment>
  )
}


export default Password

import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

const Login = (props) => {

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const formData = new FormData(event.target); // Get form data
    const queryParams = window.location.search.substring(1);
    const urlSearchParams = new URLSearchParams(queryParams);
    urlSearchParams.forEach((value, key) => {
      formData.append(key, value); // Append query parameters to form data
    });


    try {
      const response = await axios.post("/login", formData); // Make POST request
      // Handle response if needed
      window.location.href = '/lc';
    } catch (error) {
      if (error.response && error.response.status === 402) {
        Swal.fire({
          title: 'Чтобы подписаться на эту номинацию необходимо добавить портфолио',
          html:
            '<input id="swal-input1" required class="swal2-input" placeholder="Ссылка на портфолио" autofocus>' +
            '<input id="swal-input2" required class="swal2-input" placeholder="Мотивационное письмо">',
          showCancelButton: true,
          confirmButtonText: 'Отправить',
          cancelButtonText: 'Отмена',
          showLoaderOnConfirm: true,
          preConfirm: (values) => {
            const input1Value = document.getElementById('swal-input1').value;
            const input2Value = document.getElementById('swal-input2').value;
            if (!input1Value || !input2Value) {
              return Swal.showValidationMessage('Заполните обязательные поля');
            }

            // Создаем объект с данными для отправки на сервер
            const dataToSend = {
              link_portfolio: input1Value,
              link_portfolio_two: input2Value
            };

            // Выполняем POST-запрос на сервер
            return fetch('/nomination_with_portfolios', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(dataToSend)
            })
              .then(response => {
                if (!response.ok) {
                  throw new Error(response.statusText);
                }
                window.location.href = '/lc';
              })
              .catch(error => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          window.location.href = '/lc';
        });

      } else {

        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: 'error',
          title: error.response.data.error
        })
        console.error("Error sending form data:", error);
      }
    }
  };


  return (
    <React.Fragment>
      <>
        <section className="grid h-screen place-content-center bg-black text-white relative">
          <div className="absolute inset-0 flex justify-center items-center z-0">
            <img
              src="./images/login.png"
              alt=""
              className="rounded-t-lg"
            />
          </div>
          <form onSubmit={handleSubmit} className="flex flex-col items-center justify-center space-y-6 z-10 ">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 placeholder:text-white border border-white"
            />
            <div>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Пароль"
                className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 placeholder:text-white border border-white"
              />
              <p
                id="validation"
                className="text-center text-orange-500 italic text-sm"
              />
            </div>
            <button type="submit" className="rounded-lg bg-[#801EE2] h-[49px] px-4 text-white w-full">
              Войти
            </button>
            <a href={props.link} className="rounded-lg bg-white h-[49px] px-4 text-black w-full align-bottom text-center p-3.5">
              Регистрация
            </a>
            <a href="https://t.me/youngdesignspb_bot" target="_blank" className="rounded-lg bg-white h-[49px] px-4 text-black w-full align-bottom text-center p-3.5">
              Сменить пароль
            </a>

          </form>
        </section>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n  .contax{\n    background-color: transparent;\n    backdrop-filter: blur(10px);\n  }\n"
          }}
        />
      </>
    </React.Fragment>
  )
}


export default Login

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";

const Edit = (props) => {
  const [user, setUser] = useState(props.user)
  const [selectedType, setSelectedType] = useState(user.user_type);
  const [diploma, setDiplome] = useState(user.diploma)
  const [selectedEducation, setSelectedEducation] = useState(user.type_of_education);

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };


  const handleEducation = (event) => {
    setSelectedEducation(event.target.value);
  };

  const handleRmDiploma = () => {
    // Выполняем запрос на API
    axios.post('/remove_diplom')
      .then(response => {
        // Обрабатываем успешный ответ от API
        setDiplome(null)
      })
      .catch(error => {
        // Обрабатываем ошибку запроса
        console.error('API Error:', error);
      });
  };


  useEffect(() => {
    console.log(user.diploma);
  }, []); // Пустой массив зависимостей

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const formData = new FormData(event.target); // Get form data
    const queryParams = window.location.search.substring(1);
    const urlSearchParams = new URLSearchParams(queryParams);
    urlSearchParams.forEach((value, key) => {
      formData.append(key, value); // Append query parameters to form data
    });

    try {
      const response = await axios.post("/user_edit", formData); // Make POST request
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: "Успешно"
      })
      const modalBackdrop = document.querySelector('[modal-backdrop]');
      if (modalBackdrop) {
        modalBackdrop.classList.add('hidden');
      }
      const defaultModal = document.getElementById('defaultModal');
      if (defaultModal) {
        defaultModal.classList.add('hidden');
      }
      // Найти элемент, который вы хотите обновить
      let productsElement = document.getElementById("userAvatar");

      // Отправить запрос на сервер и получить контент
      fetch(window.location.href)
        .then(response => response.text())
        .then(data => {
          // Создать временный контейнер для разбора полученного контента
          let tempContainer = document.createElement("div");
          tempContainer.innerHTML = data;

          // Найти внутри временного контейнера нужные элементы
          let newProducts = tempContainer.querySelectorAll("#userAvatar > *");

          // Очистить текущее содержимое элемента и добавить новый контент
          productsElement.innerHTML = "";
          newProducts.forEach(function (item) {
            productsElement.appendChild(item);
          });
        })
        .catch(error => {
          console.error("Ошибка при загрузке контента:", error);
        });

    } catch (error) {
      // Handle error
      console.error("Error sending form data:", error);
    }
  };


  return (
    <React.Fragment>
      <div className="relative p-4 w-full max-w-lg h-full md:h-auto">
        {/* Modal content */}
        <div className="relative p-4 rounded-lg shadow bg-gray-800 sm:p-5 h-[600px] lg:h-auto overflow-y-auto">
          {/* Modal header */}
          <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 border-gray-600">
            <h3 className="text-lg font-semibold text-white">Редактирование профиля</h3>
            <button
              type="button"
              className="bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
              data-modal-toggle="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* Modal body */}
          <form onSubmit={handleSubmit}>
            <div className="grid gap-4 mb-4 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  ФИО
                </label>
                <input
                  type="text"
                  name="full_name"
                  id="name"
                  className="border  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500"
                  placeholder="ФИО"
                  required=""
                  defaultValue={user.full_name}
                />
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Телефон
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="border  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Телефон"
                  required=""
                  defaultValue={user.phone}
                />
              </div>
              <div>
                <label
                  htmlFor="telegram"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Телеграм
                </label>
                <input
                  type="text"
                  name="telegram"
                  id="telegram"
                  className="border  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Телеграм"
                  required=""
                  defaultValue={user.telegram}
                />
              </div>
              <div>
                <label
                  htmlFor="telegram"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Ссылка на портфолио
                </label>
                <input
                  type="text"
                  name="portfolio"
                  id="portfolio"
                  className="border  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Ссылка на портфолио"
                  required=""
                  defaultValue={user.portfolio}
                />
              </div>

              <div className="sm:col-span-2">

                <div>
                  <label class="block mb-2 text-sm font-medium text-white" for="file_input">Фото профиля</label>
                  <input
                    class="block text-sm bg-slate-800/50 text-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none w-full"
                    id="file_input"
                    type="file"
                    name="avatar"
                  />
                  <p
                    id="validation"
                    className="text-center text-orange-500 italic text-sm"
                  />
                </div>
              </div>

            </div>
            <button
              type="submit"
              className="text-white inline-flex items-center bg-slate-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:bg-primary-700 focus:ring-primary-800"
            >
              <svg
                className="mr-1 -ml-1 w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
              Сохранить
            </button>
          </form>
        </div>
      </div>

    </React.Fragment>
  )
}


export default Edit

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

const Test = (props) => {
  const [selectedType, setSelectedType] = useState("Студент"); // Default to "student"
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);


  const [selectedCountry, setSelectedCountry] = useState("1");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    // Загрузка списка регионов при изменении выбранной страны
    if (selectedCountry !== "") {
      fetchRegions(selectedCountry);
    }
  }, [selectedCountry]);

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleSubmit = async (event) => {
    debugger
    event.preventDefault(); // Prevent default form submission

    const formData = new FormData(event.target); // Get form data
    const queryParams = window.location.search.substring(1);
    const urlSearchParams = new URLSearchParams(queryParams);
    urlSearchParams.forEach((value, key) => {
      formData.append(key, value); // Append query parameters to form data
    });

    try {
      const response = await axios.post("/sign_up", formData); // Make POST request
      // Handle response if needed
      window.location.href = '/lc';
    } catch (error) {
      // Handle error
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'error',
        title: error.response.data.error
      })
    }
  };

  const fetchRegions = () => {
    // Создайте URL для запроса к API ВКонтакте
    const apiUrl = `/get_regions`;

    // Выполните запрос к API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Парсинг данных и установка регионов в состояние
        const regionsData = data;
        setRegions(regionsData);
      })
      .catch((error) => {
        console.error("Ошибка при загрузке регионов:", error);
      });
  };

  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption); // Обновляем выбранный регион

  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption); // Обновляем выбранный регион

  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'white' : 'blue',
      padding: 20,
    }),
    control: (styles) => ({
      ...styles,
      width: "20rem",
      height: "49px",
      borderRadius: "0.5rem",
      backgroundColor: "transparent",
      backdropFilter: "blur(10px)"
    }),
    input: (styles) => ({
      ...styles,
      color: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none"
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#fff"
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = "#fff"
      return { ...provided, opacity, transition, color };
    }
  }

  async function loadOptions(search, loadedOptions) {
    const response = await fetch(`/get_regions?q=${search}`);
    const responseJSON = await response.json();

    return {
      options: responseJSON.map((region) => ({
        value: region.id,
        label: region.title,
      })),
    };
  }

  async function loadOptionsCity(search, loadedOptions) {
    const region = selectedRegion
    const response = await fetch(`/get_cities?regionId=${region.value}&q=${search}`);
    const responseJSON = await response.json();

    return {
      options: responseJSON.map((city) => ({
        value: city.id,
        label: city.title,
      })),
    };
  }

  return (
    <React.Fragment>
      <main>
        <section className="mt-20 mb-14 grid h-full place-content-center bg-black text-white relative">
          <div className="absolute inset-0 flex justify-center items-center z-0 right-60">
            <img src='./images/test44.png' alt="" className="rounded-t-lg" style={{ width: 400 }} />
          </div>
          <form className="z-10" onSubmit={handleSubmit}>
            <div className="flex flex-col items-center justify-center space-y-6 z-10 ">
              <div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Почта"
                  className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                  required
                />
                <p
                  id="validation"
                  className="text-center text-orange-500 italic text-sm"
                />
              </div>
              <div>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Пароль"
                  className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                  required
                />
                <p
                  id="validation"
                  className="text-center text-orange-500 italic text-sm"
                />
              </div>
              <input
                type="text"
                id="name"
                name="full_name"
                placeholder="ФИО"
                className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                required
              />
              <div>
                <InputMask
                  mask="+7 (999) 999-99-99" // Здесь можно задать маску для номера телефона
                  placeholder="Телефон"
                  id="phone"
                  name="phone"
                  className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                  required
                />
                <p
                  id="validation"
                  className="text-center text-orange-500 italic text-sm"
                />
              </div>
              <div>
                <input
                  type="telegram"
                  id="telegram"
                  name="telegram"
                  placeholder="Телеграм"
                  className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                  required
                />
                <p
                  id="validation"
                  className="text-center text-orange-500 italic text-sm"
                />
              </div>
              <div>
                <div className="custom-select">
                  <AsyncPaginate
                    value={selectedRegion}
                    loadOptions={loadOptions}
                    onChange={handleRegionChange}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div>
                <AsyncPaginate
                  value={selectedCity}
                  loadOptions={loadOptionsCity}
                  styles={customStyles}
                  onChange={handleCityChange}
                  isDisabled={!selectedRegion} // Блокируем выбор города, если регион не выбран
                  placeholder="Выберите город"

                />



                <p
                  id="validation"
                  className="text-center text-orange-500 italic text-sm"
                />
              </div>
              <div className="flex w-full gap-3">
                <div className="flex w-full items-center pl-4 border border-gray-200 rounded backdrop-blur-lg bg-slate-800/50">
                  <input
                    required
                    defaultChecked=""
                    id="bordered-radio-2"
                    type="radio"
                    defaultValue="Студент"
                    name="user_type"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    onChange={handleTypeChange}
                  />
                  <label
                    htmlFor="bordered-radio-2"
                    className="w-full py-4 ml-2 text-sm font-medium text-white"
                  >
                    Студент
                  </label>
                </div>

                <div className="flex w-full items-center pl-4 border border-gray-200 rounded backdrop-blur-lg bg-slate-800/50">
                  <input
                    id="bordered-radio-1"
                    type="radio"
                    defaultValue="Выпускник"
                    name="user_type"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    onChange={handleTypeChange}
                  />
                  <label
                    htmlFor="bordered-radio-1"
                    className="w-full py-4 ml-2 text-sm font-medium text-white"
                  >
                    Выпускник
                  </label>
                </div>
              </div>
              {selectedType === "Выпускник" && (
                <div>
                  <label class="block mb-2 text-sm font-medium text-white" for="file_input">Загрузите диплом</label>
                  <input
                    class="block text-sm bg-slate-800/50 text-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none w-80"
                    id="file_input"
                    type="file"
                    name="diploma"
                    required />
                  <p
                    id="validation"
                    className="text-center text-orange-500 italic text-sm"
                  />
                </div>
              )}
              <div className="flex w-full gap-3">
                <div className="flex w-full items-center pl-4 border border-gray-200 rounded backdrop-blur-lg bg-slate-800/50">
                  <input
                    required
                    defaultChecked=""
                    id="bordered-radio-3"
                    type="radio"
                    defaultValue="Вуз"
                    name="type_of_education"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                  />
                  <label
                    htmlFor="bordered-radio-3"
                    className="w-full py-4 ml-2 text-sm font-medium text-white"
                  >
                    Университет
                  </label>
                </div>

                <div className="flex w-full items-center pl-4 border border-gray-200 rounded backdrop-blur-lg bg-slate-800/50">
                  <input
                    id="bordered-radio-4"
                    type="radio"
                    defaultValue="Колледж"
                    name="type_of_education"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                  />
                  <label
                    htmlFor="bordered-radio-4"
                    className="w-full py-4 ml-2 text-sm font-medium text-white"
                  >
                    Колледж
                  </label>
                </div>
              </div>
              <div>
                <input
                  required
                  type="course"
                  id="course"
                  name="course"
                  placeholder="Курс"
                  className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                />
                <p
                  id="validation"
                  className="text-center text-orange-500 italic text-sm"
                />
              </div>
              <div>
                <input
                  required
                  type="faculty"
                  id="faculty"
                  name="faculty"
                  placeholder="Направление/факультет"
                  className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                />
                <p
                  id="validation"
                  className="text-center text-orange-500 italic text-sm"
                />
              </div>
              <button type="submit" className="rounded-lg bg-white h-[49px] px-4 text-black w-full">
                Регистрация
              </button>
              <a href={props.link} className="rounded-lg bg-[#801EE2] h-[49px] px-4 text-white w-full align-bottom text-center p-3.5">
                Войти
              </a>

            </div>
          </form>
        </section>
      </main>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n  .contax{\n    background-color: transparent;\n    backdrop-filter: blur(10px);\n  }\n"
        }}
      />

    </React.Fragment>
  )
}


export default Test

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

const SignUp = (props) => {
  const [selectedType, setSelectedType] = useState("Студент"); // Default to "student"
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectEducation, setEducation] = useState(["Вуз"])

  const [selectedCountry, setSelectedCountry] = useState("1");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedEducationCity, setSelectedEducationCity] = useState("");
  const [selectedEducationUniver, setEducationUniver] = useState("");
  const [selectedUniverFacult, setUniverFacult] = useState("");


  useEffect(() => {
    // Загрузка списка регионов при изменении выбранной страны
    if (selectedCountry !== "") {
      fetchRegions(selectedCountry);
    }
  }, [selectedCountry]);

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };


  const handleEducationChange = (event) => {
    setEducation(event.target.value);
  };


  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const region = selectedRegion
    const city = selectedCity

    const formData = new FormData(event.target); // Get form data
    formData.append("city_id", city.value);
    formData.append("city_name", city.label);

    formData.append("educational_institution", selectedEducationUniver.label);
    if (selectEducation === "Вуз") {
      formData.append("faculty_or_specialization", selectedUniverFacult.label);

    }

    const queryParams = window.location.search.substring(1);
    const urlSearchParams = new URLSearchParams(queryParams);
    urlSearchParams.forEach((value, key) => {
      formData.append(key, value); // Append query parameters to form data
    });

    try {
      const response = await axios.post("/sign_up", formData); // Make POST request
      // Handle response if needed
      window.location.href = '/lc';
    } catch (error) {
      if (error.response && error.response.status === 402) {
        let link_one = "";
        let link_two = "";

        if (error.response.data.error.link_one) {
          link_one = '<input id="swal-input1" required class="swal2-input" placeholder="Ссылка на портфолио" autofocus>'
        }

        if (error.response.data.error.link_two) {
          link_two = '<input id="swal-input2" required class="swal2-input" placeholder="Мотивационное письмо">'
        }
        Swal.fire({
          title: 'Чтобы подписаться на эту номинацию необходимо добавить портфолио',
          html: (link_one || "") + (link_two || ""),
          showCancelButton: true,
          confirmButtonText: 'Отправить',
          cancelButtonText: 'Отмена',
          showLoaderOnConfirm: true,
          preConfirm: (values) => {
            const input1 = document.getElementById('swal-input1');
            const input2 = document.getElementById('swal-input2');

            let input1Value = "";
            let input2Value = "";

            const dataToSend = {}
            if (input1) {
              input1Value = input1.value;
              if (!input1Value) {
                Swal.showValidationMessage('Введите ссылку на портфолио');
                return false; // Остановка отправки формы
              }
              dataToSend.link_portfolio = input1Value;
            }

            if (input2) {
              input2Value = input2.value;
              if (!input2Value) {
                Swal.showValidationMessage('Введите мотивационное письмо');
                return false; // Остановка отправки формы
              }
              dataToSend.link_portfolio_two = input2Value;
            }

            const queryParams = new URLSearchParams(dataToSend);
            const urlWithParams = `/nomination_with_portfolios?${queryParams.toString()}`;

            return fetch(urlWithParams, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            }).then(response => {
              if (!response.ok) {
                throw new Error(response.statusText);
              }
              window.location.href = '/lc';
            })
              .catch(error => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          window.location.href = '/lc';
        });

      } else {
        const errorFields = Object.keys(error.response.data.error);
        const errorMessages = errorFields.map((field) => error.response.data.error[field].join(', '));
        const erros = errorMessages.join(', ');

        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: 'error',
          title: erros
        })
      }
    };
  }
  const fetchRegions = () => {
    // Создайте URL для запроса к API ВКонтакте
    const apiUrl = `/get_regions`;

    // Выполните запрос к API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Парсинг данных и установка регионов в состояние
        const regionsData = data;
        setRegions(regionsData);
      })
      .catch((error) => {
        console.error("Ошибка при загрузке регионов:", error);
      });
  };

  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption); // Обновляем выбранный регион

  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption); // Обновляем выбранный регион

  };

  const handleCityChangeEducation = (selectedOption) => {
    setSelectedEducationCity(selectedOption); // Обновляем выбранный регион
  }

  const handleFacult = (selectedOption) => {
    setUniverFacult(selectedOption); // Обновляем выбранный регион
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'white' : 'blue',
      padding: 20,
    }),
    control: (styles) => ({
      ...styles,
      width: "20rem",
      height: "49px",
      borderRadius: "0.5rem",
      backgroundColor: "transparent",
      backdropFilter: "blur(10px)"
    }),
    input: (styles) => ({
      ...styles,
      color: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none"
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#fff"
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = "#fff"
      return { ...provided, opacity, transition, color };
    }
  }

  async function loadOptions(search, loadedOptions) {
    const response = await fetch(`/get_regions?q=${search}`);
    const responseJSON = await response.json();

    return {
      options: responseJSON.map((region) => ({
        value: region.id,
        label: region.title,
      })),
    };
  }

  async function loadOptionsCity(search, loadedOptions) {
    const response = await fetch(`/get_cities?&q=${search}`);
    const responseJSON = await response.json();

    return {
      options: responseJSON.map((city) => ({
        value: city.id,
        label: city.region ? `${city.title} (${city.region})` : city.title,
      })),
    };
  }

  async function loadOptionsCityEducation(search, loadedOptions) {
    const region = selectedRegion
    const response = await fetch(`/get_cities?q=${search}`);
    const responseJSON = await response.json();

    return {
      options: responseJSON.map((city) => ({
        value: city.id,
        label: city.region ? `${city.title} (${city.region})` : city.title,

      })),
    };
  }

  async function loadOptionsUniverEducation(search, loadedOptions) {
    const city = selectedEducationCity
    let response = ""

    if (selectEducation === "Колледж") {
      response = await fetch(`/get_college?city_id=${city.value}&q=${search}`);
    } else {
      response = await fetch(`/get_univer?city_id=${city.value}&q=${search}`);
    }
    const responseJSON = await response.json();

    return {
      options: responseJSON.map((city) => ({
        value: city.id,
        label: city.title

      })),
    };
  }


  async function loadOptionsFacult(search, loadedOptions) {
    const univer = selectedEducationUniver

    const response = await fetch(`/get_faculty?university_id=${univer.value}&q=${search}`);
    const responseJSON = await response.json();

    return {
      options: responseJSON.map((city) => ({
        value: city.id,
        label: city.title

      })),
    };
  }
  const handleUniverChangeEducation = (selectedOption) => {
    setEducationUniver(selectedOption); // Обновляем выбранный регион
  }

  return (
    <React.Fragment>
      <main>
        <section className="mt-20 mb-14 grid h-full place-content-center bg-black text-white relative">
          <div className="absolute inset-0 flex justify-center items-center z-0 right-60">
            <img src='./images/test44.png' alt="" className="rounded-t-lg" style={{ width: 400 }} />
          </div>
          <form className="z-10" onSubmit={handleSubmit}>
            <div className="flex flex-col items-center justify-center space-y-6 z-10 ">
              <div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Почта"
                  className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                  required
                />
                <p
                  id="validation"
                  className="text-center text-orange-500 italic text-sm"
                />
              </div>
              <div>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Пароль"
                  className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                  required
                />
                <p
                  id="validation"
                  className="text-center text-orange-500 italic text-sm"
                />
              </div>
              <input
                type="text"
                id="name"
                name="full_name"
                placeholder="ФИО"
                className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                required
              />
              <div>
                <InputMask
                  mask="+7 (999) 999-99-99" // Здесь можно задать маску для номера телефона
                  placeholder="Телефон"
                  id="phone"
                  name="phone"
                  className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                  required
                />
                <p
                  id="validation"
                  className="text-center text-orange-500 italic text-sm"
                />
              </div>
              <div>
                <input
                  type="telegram"
                  id="telegram"
                  name="telegram"
                  placeholder="Телеграм"
                  className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                  required
                />
                <p
                  id="validation"
                  className="text-center text-orange-500 italic text-sm"
                />
              </div>
              <div>
                <AsyncPaginate
                  required
                  value={selectedCity}
                  loadOptions={loadOptionsCity}
                  styles={customStyles}
                  onChange={handleCityChange}
                  placeholder="Выберите город"

                />



                <p
                  id="validation"
                  className="text-center text-orange-500 italic text-sm"
                />
              </div>
              <div className="flex w-full gap-3">
                <div className="flex w-full items-center pl-4 border border-gray-200 rounded backdrop-blur-lg bg-slate-800/50">
                  <input
                    required
                    defaultChecked=""
                    id="bordered-radio-2"
                    type="radio"
                    defaultValue="Студент"
                    name="user_type"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    onChange={handleTypeChange}
                  />
                  <label
                    htmlFor="bordered-radio-2"
                    className="w-full py-4 ml-2 text-sm font-medium text-white"
                  >
                    Студент
                  </label>
                </div>

                <div className="flex w-full items-center pl-4 border border-gray-200 rounded backdrop-blur-lg bg-slate-800/50">
                  <input
                    id="bordered-radio-1"
                    type="radio"
                    defaultValue="Выпускник"
                    name="user_type"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    onChange={handleTypeChange}
                  />
                  <label
                    htmlFor="bordered-radio-1"
                    className="w-full py-4 ml-2 text-sm font-medium text-white"
                  >
                    Выпускник
                  </label>
                </div>
              </div>
              {selectedType === "Выпускник" && (
                <div>
                  <label class="block mb-2 text-sm font-medium text-white" for="file_input">Загрузите диплом</label>
                  <input
                    class="block text-sm bg-slate-800/50 text-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none w-80"
                    id="file_input"
                    type="file"
                    name="diploma"
                    required />
                  <p
                    id="validation"
                    className="text-center text-orange-500 italic text-sm"
                  />
                </div>
              )}
              <div className="flex w-full gap-3">
                <div className="flex w-full items-center pl-4 border border-gray-200 rounded backdrop-blur-lg bg-slate-800/50">
                  <input
                    required
                    defaultChecked=""
                    id="bordered-radio-3"
                    type="radio"
                    defaultValue="Вуз"
                    name="type_of_education"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    onChange={handleEducationChange}
                  />
                  <label
                    htmlFor="bordered-radio-3"
                    className="w-full py-4 ml-2 text-sm font-medium text-white"
                  >
                    Университет
                  </label>
                </div>

                <div className="flex w-full items-center pl-4 border border-gray-200 rounded backdrop-blur-lg bg-slate-800/50">
                  <input
                    id="bordered-radio-4"
                    type="radio"
                    defaultValue="Колледж"
                    name="type_of_education"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    onChange={handleEducationChange}
                  />
                  <label
                    htmlFor="bordered-radio-4"
                    className="w-full py-4 ml-2 text-sm font-medium text-white"
                  >
                    Колледж
                  </label>
                </div>
              </div>
              {selectEducation === "Вуз" && (
                <>
                  <div>
                    <AsyncPaginate
                      required
                      value={selectedEducationCity}
                      loadOptions={loadOptionsCityEducation}
                      onChange={handleCityChangeEducation}
                      styles={customStyles}
                      placeholder="Выберите город" />
                  </div>
                  <div>
                    <AsyncPaginate
                      required
                      value={selectedEducationUniver}
                      loadOptions={loadOptionsUniverEducation}
                      onChange={handleUniverChangeEducation}
                      styles={customStyles}
                      placeholder="Найдите ваш вуз"
                      isDisabled={!selectedEducationCity} />
                  </div>
                  <div>
                    <AsyncPaginate
                      required
                      value={selectedUniverFacult}
                      loadOptions={loadOptionsFacult}
                      onChange={handleFacult}
                      styles={customStyles}
                      placeholder="Найдите ваш факультет"
                      isDisabled={!selectedEducationUniver} />
                  </div>
                  <div>
                    <input
                      required
                      type="course"
                      id="course"
                      name="course"
                      placeholder="Курс"
                      className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                    />
                    <p
                      id="validation"
                      className="text-center text-orange-500 italic text-sm"
                    />
                  </div>

                </>

              )}
              {selectEducation === "Колледж" && (
                <>
                  <div>
                    <AsyncPaginate
                      required
                      value={selectedEducationCity}
                      loadOptions={loadOptionsCityEducation}
                      onChange={handleCityChangeEducation}
                      styles={customStyles}
                      placeholder="Выберите город" />
                  </div>
                  <div>
                    <AsyncPaginate
                      required
                      value={selectedEducationUniver}
                      loadOptions={loadOptionsUniverEducation}
                      onChange={handleUniverChangeEducation}
                      styles={customStyles}
                      placeholder="Найдите ваш колледж"
                      isDisabled={!selectedEducationCity} />
                  </div>
                  <div>
                    <input
                      required
                      type="faculty_or_specialization"
                      id="faculty_or_specialization"
                      name="faculty_or_specialization"
                      placeholder="Специализация"
                      className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                    />
                    <p
                      id="validation"
                      className="text-center text-orange-500 italic text-sm"
                    />
                  </div>
                  <div>
                    <input
                      required
                      type="course"
                      id="course"
                      name="course"
                      placeholder="Курс"
                      className="h-[49px] contax w-80 appearance-none rounded-lg bg-slate-800/50 p-2 px-4 focus:ring-2 focus:ring-orange-500 placeholder:text-white border border-white"
                    />
                    <p
                      id="validation"
                      className="text-center text-orange-500 italic text-sm"
                    />
                  </div>

                </>

              )}
              <div className="flex w-80 items-center mb-4">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  defaultValue=""
                  required
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ml-2 text-sm font-medium text-gray-300"
                >
                  Я принимаю условия <a href="https://drive.google.com/file/d/18gdm_yO056HSPgx7P3JO4d9OAF8wMLOx/view?usp=sharing">Положения о конкурсе</a>
                </label>
              </div>
              <button type="submit" className="rounded-lg bg-white h-[49px] px-4 text-black w-full">
                Регистрация
              </button>
              <a href={props.link} className="rounded-lg bg-[#801EE2] h-[49px] px-4 text-white w-full align-bottom text-center p-3.5">
                Войти
              </a>

            </div>
          </form>
        </section>
      </main>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n  .contax{\n    background-color: transparent;\n    backdrop-filter: blur(10px);\n  }\n"
        }}
      />

    </React.Fragment>
  )
}


export default SignUp
